
@import '~bootstrap/scss/bootstrap';
@import 'variables';
/* You can add global styles to this file, and also import other style files */

html, body, #root {
  height: 100%;
  overflow-x: hidden;
  max-width: 100vw;
}
body::-webkit-scrollbar, html::-webkit-scrollbar{
  display: none;
}
body, html{
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.fw-light200 {
  font-weight: 200;
}

body {
  font-family: "Public Sans", sans-serif;
  background-color: #FAFAFB;
  color: #252525;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

table {
  margin-bottom: 0 !important;

  tbody {
    tr:last-child {
      border-color: transparent;
    }
  }
}

a.blank-style-link {
  text-decoration: none;
  color: unset;
}

.menu-icon {
  margin-right: 10px;
  width: 16px;
}

.more-spins {
  margin: 0 32px 12px 32px;
  height: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

$grid-breakpoints: (
        xxs: $xxs-breakpoint,
        xs: $xs-breakpoint,
        sm: $sm-breakpoint,
        md: $md-breakpoint,
        lg: $lg-breakpoint,
        xl: $xl-breakpoint,
        xxl: $xxl-breakpoint,
        xxxl: $xxxl-breakpoint,
);
